import { Injectable } from '@angular/core';
import { Lesson } from '../interfaces/lesson';

@Injectable({
  providedIn: 'root'
})
export class LessonUtilitiesService {

  constructor() { }

  getBackgroundImage(lesson: Lesson): string {
    let bi: string | undefined = '';

    if (!lesson) {
      bi = 'assets/img/courses/thumb-text-lesson.png';
      return bi;
    }
    //console.log("video lesson", lesson)
    if (lesson && lesson.video_post) {
      bi = lesson.video_post.thumb_url!;
    } else if (lesson && lesson.videopost) {
      bi = lesson.videopost.thumb_url!;
    } else if (lesson.microclip) {
      bi = lesson.microclip.thumb_url ? lesson.microclip.thumb_url : lesson.microclip.video_post ? lesson.microclip.video_post.thumb_url : lesson.microclip.videopost?.thumb_url!;
    } else if (lesson.type == 'text' && lesson.icon == 'microclips') {
        var lb1 = lesson.lesson_blocks.filter( (value: any) => {
          return value.type == 'microclip';
        });

        if (lb1.length) {
          lb1 = lb1.sort( (a: any, b: any) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0);
          const first1 = lb1[0];
          if (first1 && first1.microclip) {
            bi = first1.microclip.thumbnail_url;
          }
        }
    } else if (lesson.type == 'text' && lesson.icon == 'videos') {

        let lb2 = lesson.lesson_blocks.filter( (value) => {
          return value.type == 'video';
        });

        if (lb2.length) {
          lb2 = lb2.sort( (a: any, b: any) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0);
          const first2 = lb2[0];
          if (first2 && first2.video) {
              bi = first2.video.thumb_url;
          }
        }

        if (lesson.thumbnail_url) {
          bi = lesson.thumbnail_url;
        }
    } else if (lesson.type == 'text' && lesson.icon == 'question_microclips') {
        var lb3 = lesson.lesson_blocks.filter( (value) => {
            return value.type == 'microclip';
        });

        if (lb3.length) {
          lb3 = lb3.sort( (a: any, b: any) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0);
          const first3 = lb3[0];
          if (first3 && first3.microclip) {
            bi = first3.microclip.thumb_url;
          }
        }
    } else if (lesson.type == 'text' && lesson.icon == 'question_videos') {
      let lb4 = lesson.lesson_blocks.filter( (value) => {
        return value.type == 'video';
      });

      if (lb4.length) {
        lb4 = lb4.sort( (a: any, b: any) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0);
        const first4 = lb4[0];
        if (first4 && first4.video) {
          bi = first4.video.thumb_url;
        }
      }
    } else if (lesson.type == 'text' && lesson.icon == 'normal') {
      let fil3 =lesson.lesson_blocks.filter( (value) => {
        return value.type == 'file';
      });

      fil3 = fil3.sort( (a: any, b: any) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0);

      if (fil3.length > 0){
        bi = fil3[0]?.file?.pathUrlLarge ? fil3[0]?.file?.pathUrlLarge : fil3[0]?.file?.pathUrl;
      }
    } else {
      bi = lesson.thumbnail_url!;
    }

    if (typeof bi === 'undefined' || bi == ''){
      bi = 'assets/img/courses/thumb-text-lesson.png';
    }

    return bi;
  };
}
