import { HostListener, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationNotPublicGuard } from './guards/organization-not-public.guard';
import { RoleGuard } from './guards/role.guard';

import { MainComponent } from './layout/main/main.component';

import { HomeComponent } from './pages/home/home.component';
// import { ProfileComponent } from './pages/profile/profile.component'
import { MainComponent as ViewerMainComponent } from './pages/course-viewer/main/main.component';
import { OrderResultComponent } from './pages/courses/order-result/order-result.component';
import { InviteComponent } from './pages/invite/invite.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { OrganizationPublicGuard } from './guards/organization-public.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { AcceptInvitationComponent } from './pages/accept-invitation/accept-invitation.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { CancelEmailComponent } from './pages/cancel-email/cancel-email.component';
import { CollectionsRedirectComponent } from './pages/collections-redirect/collections-redirect.component';
import { SurfComponent } from './pages/surf/surf.component';
import { Level3Component } from './pages/dh/landing/levels/level3/level3.component';
import { Level4Component } from './pages/dh/landing/levels/level4/level4.component';
import { CoursesService } from './services/courses.service';
import { LandingComponent } from './pages/dh/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'discover',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: '',
        component: HomeComponent,
        canActivate: [OrganizationPublicGuard],
      },
      {
        // component: HomeComponent,
        // canActivate: [OrganizationPublicGuard]
        path: 'acceptinvitation/:slug/:organization/member/:member',
        component: HomeComponent,
        canActivate: [OrganizationPublicGuard],
      },
      {
        path: 'order_result',
        component: OrderResultComponent,
      },
      {
        path: 'courses',
        canActivate: [OrganizationPublicGuard],
        loadChildren: () =>
          import('./pages/courses/courses.module').then((m) => m.CoursesModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'playlists',
        loadChildren: () =>
          import('./pages/playlists/playlists.module').then(
            (m) => m.PlaylistsModule
          ),
        canActivate: [OrganizationNotPublicGuard, RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'collections/:id',
        component: CollectionsRedirectComponent,
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'collections/:id/acceptinvitation',
        component: CollectionsRedirectComponent,
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'groups/:id/acceptinvitation',
        component: CollectionsRedirectComponent,
        canActivate: [OrganizationNotPublicGuard, RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'collections/:id',
        component: CollectionsRedirectComponent,
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'collections/:id/acceptinvitation',
        component: CollectionsRedirectComponent,
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'groups/:id/acceptinvitation',
        component: CollectionsRedirectComponent,
        canActivate: [OrganizationNotPublicGuard, RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'video',
        loadChildren: () =>
          import('./pages/videos/video.module').then((m) => m.VideoModule),
        canActivate: [OrganizationNotPublicGuard, RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'groups',
        loadChildren: () =>
          import('./pages/groups/group.module').then((m) => m.GroupModule),
        canActivate: [OrganizationNotPublicGuard, RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'dh-teams/:id/acceptinvitation',
        redirectTo: 'dh/team/:id/acceptinvitation',
      },
      {
        path: 'dh-teams',
        redirectTo: 'dh/team',
      },
      {
        path: 'dh-profile',
        redirectTo: 'dh/profile',
      },
      {
        path: 'dh',
        loadChildren: () =>
          import('./pages/dh/dh.module').then((m) => m.DhModule),
      },
      {
        path: 'profile',
        // component: ProfileComponent,
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [
            'user',
            'normal',
            'team_trainer',
            'trainer',
            'moderator',
            'super_user',
          ],
        },
      },
      {
        path: 'organization/:id',
        loadChildren: () =>
          import('./pages/organization/organization.module').then(
            (m) => m.OrganizationModule
          ),
        canActivate: [RoleGuard],
        data: {
          requiredRoles: ['moderator', 'super_user'],
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./pages/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [RoleGuard],
        data: {
          requiredRoles: ['super_user'],
        },
      },
      {
        path: 'doublehealixprofile/competencies',
        component: Level3Component,
        // canActivate: [DhAccessGuard],
      },
      {
        path: 'doublehealixprofiel/competenties',
        component: Level3Component,
        // canActivate: [DhAccessGuard],
      },
      {
        path: 'doublehealixprofile/teamroles',
        component: Level4Component,
        // canActivate: [DhAccessGuard],
      },
      {
        path: 'doublehealixprofiel/teamrollen',
        component: Level4Component,
        // canActivate: [DhAccessGuard],
      },
      {
        path: 'doublehealixprofiel',
        component: LandingComponent,
        // canActivate: [DhAccessGuard],
      },
      {
        path: 'doublehealixprofile',
        component: LandingComponent,
        // canActivate: [DhAccessGuard],
      },
    ],
  },
  {
    path: 'course-viewer/:course_id',
    component: ViewerMainComponent,
    loadChildren: () =>
      import('./pages/course-viewer/course-viewer.module').then(
        (m) => m.CourseViewerModule
      ),
    canActivate: [RoleGuard],
    data: {
      requiredRoles: [
        'user',
        'normal',
        'team_trainer',
        'trainer',
        'moderator',
        'super_user',
      ],
    },
  },
  {
    path: 'reset_password/:hash',
    component: ResetPasswordComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'invite/:hash',
    component: InviteComponent,
  },
  // {
  //   // component: HomeComponent,
  //   // canActivate: [OrganizationPublicGuard]
  //   path: 'acceptinvitation/:slug/:organization/member/:member',
  //   component: HomeComponent,
  //    canActivate: [OrganizationPublicGuard],

  // },
  {
    path: ':username/confirm_email/:token',
    component: ConfirmEmailComponent,
    canActivate: [RoleGuard],
    data: {
      requiredRoles: [
        'user',
        'normal',
        'team_trainer',
        'trainer',
        'moderator',
        'super_user',
      ],
    },
  },
  {
    path: ':username/cancel_email/:token',
    component: CancelEmailComponent,
    canActivate: [RoleGuard],
    data: {
      requiredRoles: [
        'user',
        'normal',
        'team_trainer',
        'trainer',
        'moderator',
        'super_user',
      ],
    },
  },
  {
    path: 'login/:lang',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'signup/:lang',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'auth/surf',
    component: SurfComponent,
    canActivate: [NotAuthenticatedGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
// scrollPositionRestoration: window.innerWidth <= 768 ? 'disabled' : 'top',
