import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Comment } from 'src/app/interfaces/comment';
import { User } from 'src/app/interfaces/user';
import { Video } from 'src/app/interfaces/video/video';
import { AuthService } from 'src/app/services/auth.service';
import { VideoService } from 'src/app/services/video.service';
import { GroupsService } from 'src/app/services/groups.service';

@Component({
  selector: 'app-comments-container',
  templateUrl: './comments-container.component.html',
  styleUrls: ['./comments-container.component.scss'],
})
export class CommentsContainerComponent implements OnInit {
  @Input() video: Video | undefined;
  @Input() playListId:  any | null = null;
  @Input() activity: any | null = null;
  @Input() comments: any = [];
  @Input() title: Boolean = true;
  @Input() placeholder: string = '';
  @Input() type: string = '';
  @Output() countComment: EventEmitter<any> = new EventEmitter();

  loading = false;
  addingComment = false;
  groupId: number = 0;
  activityId: number = 0;
  //comments: Array<Comment> = [];
  me: User | null = null;

  newComment = '';

  faCircleNorch = faCircleNotch;

  constructor(
    private videoService: VideoService,
    private authService: AuthService,
    private groupsService: GroupsService
  ) {
    this.authService.user.subscribe((user) => {
      this.me = user;
    });
  }

  ngOnInit(): void {
  //  console.log('this.playListId',this.playListId)
    if (this.video) {
      this.type = 'video';
      this.loadVideoComments();
    }
    if (
      this.type == 'activity_group' ||
      this.type == 'commentaryConversation' ||
      this.type == 'file'
    ) {
      this.groupId = this.activity.collection_id;
      this.activityId = this.activity.id;
      this.comments = this.activity.comment;
    }
  }

  loadVideoComments() {
    this.loading = true;
    this.videoService.getComments(this.video?.id || 0).then((response) => {
      this.comments = response.data.video.comments;
      this.loading = false;
    });
  }

  addComment() {
    this.addingComment = true;
    let commentPromise: Promise<any> | null;

    let newComment = {
      name: this.me?.username,
      content: this.newComment,
      date: new Date(),
      avatar: this.me?.avatar,
      avatarPathUrlLarge: this.me?.avatarPathUrlLarge,
      avatarPathUrlMedium: this.me?.avatarPathUrlMedium,
      avatarPathUrlSmall: this.me?.avatarPathUrlSmall,
      user: this.me,
    };

    let params = {
      comment: this.newComment,
      playlist_id: this.playListId
    };

    if (this.type == 'video') {
      commentPromise = this.addVideoComment(params);
      if (commentPromise) {
        commentPromise.then((response) => {
          this.comments.push(response.data.comment);
          this.countComment.emit(this.comments.length)
          this.newComment = '';
          this.addingComment = false;
        });
      }
    }

    if (this.type == 'activity_group') {
      commentPromise = this.addGroupActivityComment(params);
      if (commentPromise) {
        commentPromise.then((response) => {
          this.comments.push(response.data.comment);
          this.newComment = '';
          this.addingComment = false;
        });
      }
    }

    if (this.type == 'commentaryConversation') {
      this.groupsService
        .postConversationComment(this.activity.id, this.newComment)
        .then((resp) => {
          console.log(resp.data);
          this.comments.push(resp.data.comment);
          this.countComment.emit(this.comments.length)
          this.newComment = '';
          this.addingComment = false;
        });
    }
    if (this.type == 'file') {
      this.groupsService
        .postConversationCommentFile(this.activity.id, this.newComment)
        .then((resp: any) => {
          let commentary = {
            comment: resp.data.comment,
            user: resp.data.user,
            created_at: resp.data.created_at,
            id: resp.data.id,
          };

          this.comments.push(commentary);
          this.countComment.emit(this.comments.length)
          this.newComment = '';
          this.addingComment = false;
        });
    }
  }
  async addGroupActivityComment(params: any): Promise<any> {
    return this.groupsService.postActivityComment(
      this.groupId,
      this.activityId,
      params
    );
  }

  async addVideoComment(params: any): Promise<any> {
    return this.videoService.postComment(this.video?.id || 0, params);
  }

  public refreshDelete(index: number) {
    this.comments.splice(index, 1);
  }

  public refreshEdit(data: any, index: any) {
    this.comments[index].comment = data.comment;
  }
}
